.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  color: white;
}

.nav-item {
  padding: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-item .icon {
  font-size: 24px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .bottom-nav {
    display: none;
  }
}
