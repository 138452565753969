.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  padding: 20px;
  padding-top: 103px;
}

@media (max-width: 400px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .gallery-item {
    height: 200px !important;
  }
}
/* .gallery-item {
  width: 350px;
  height: 220px;
  object-fit: cover;
  transition: transform 0.3s ease;
} */

.gallery-item {
  display: flex; /* Użyj flexa do wyśrodkowania obrazu */
  justify-content: center; /* Wyśrodkowanie w poziomie */
  align-items: center; /* Wyśrodkowanie w pionie */
  width: 100%; /* Szerokość elementu */
  height: 320px; /* Zdefiniowana wysokość */
  overflow: hidden; /* Ukrywanie nadmiaru */
  transition: transform 0.3s ease;
}

.gallery-item img {
  max-width: 100%; /* Maksymalna szerokość obrazu */
  max-height: 100%; /* Maksymalna wysokość obrazu */
  object-fit: cover; /* Dopasowanie obrazu zachowujące proporcje */
}

.gallery-item:hover {
  transform: scale(1.05);
}
