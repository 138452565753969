.main-div {
  overflow-x: hidden;
}

.fullscreen-image {
  background-image: url("./../images/jasieniec.jpg") !important;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 80px;
  background-position: center center;
  background-size: cover;
}

.first-row {
  background-size: cover;
  justify-content: space-between;
  height: 300px;
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  height: 100%;
}

.just-text {
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: #543a27;
  font-size: 44px;
  padding: 30px;
}

.style-for-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  text-align: center;
  padding: 40px;
}

.class-for-card {
  padding-bottom: 40px;
  transition: transform 0.3s ease;
}

.card-image {
  height: 20rem;
  object-fit: cover;
}

.class-for-card:hover {
  transform: scale(1.05);
}

#galeria::before {
  content: "";
  display: block;
  height: 300px;
  margin-top: -300px;
  visibility: hidden;
}
