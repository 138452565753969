.main-container {
  border-bottom: 5px solid lightgray;
  background-color: white;
}
.navbar-row {
  background-color: pink;
}
.column-phone-number {
  color: #4dc06f;
}
.nav-elements {
  font-size: 25px;
}

a.top-nav:hover {
  color: #4dc06f !important;
  font-weight: 700 !important;
}
.logo-style {
  width: 100px;
  height: 60px;
  margin-top: 10px;
}
#navbarNav {
  flex: inherit;
  justify-content: end;
}

.top-nav {
  font-size: 16px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  font-weight: 700 !important;
}

.logo-and-phone {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
}

.phone-button {
  background-color: #f2f2f2;
  border: none;
  border-radius: 10%;
  width: 160px;
  height: 45px;
}
.phone-icon {
  color: #4dc06f;
  margin-top: -1px;
  margin-right: 5px;
}

.top-container {
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none !important;
}

.phone-number {
  color: black;
  font-weight: 700 !important;
}

@media (max-width: 992px) {
  .top-container {
    flex-wrap: nowrap !important;
    flex-direction: row;
    padding-bottom: 33px;
    position: relative;
  }
  .navbar-collapse {
    display: none;
  }
  .mr-0 {
    position: relative;
  }
  .navbar-toggler {
    /* position: absolute;
    top: 10px;
    right: 10px; */
    display: none;
  }
  .navbar-collapse {
    top: 60px;
    position: absolute;
    right: -20px;
    background-color: white;
    width: 50vw;
  }
  .nav-link:hover {
    color: #4dc06f;
  }
  .logo-and-phone {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap !important;
  }
  .phone-button {
    margin-top: 34px;
  }
  .logo-style {
    margin-top: 6px;
  }
}

@media (max-width: 575px) {
  .col-sm-6 {
    /* flex: 0 0 auto; */
    width: 50% !important;
  }
}
